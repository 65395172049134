import { Component, ErrorInfo, ReactElement, ReactNode, useCallback } from 'react';
import { isLocalDev } from 'app/util/util';
import { useTranslation } from 'react-i18next';
import { Popover } from 'shared/components/popover/popover.component';
import { AnchorButton, Button } from 'shared/components/elements/elements.components';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { useRepository } from 'app/hooks/ajax/use-repository.hook';
import { AuthRepository } from 'app/repositories/auth.repository';
import { addReactError } from '@datadog/browser-rum-react';

import './error-boundary.component.scss';

export const DATA_LEVEL_ERROR = 'DATA_LEVEL_ERROR';
export const PAGE_LEVEL_ERROR = 'PAGE_LEVEL_ERROR';
export const SITE_LEVEL_ERROR = 'SITE_LEVEL_ERROR';

interface BoundaryProps {
  render?: (error: Error, errorInfo: ErrorInfo) => ReactElement<any>;
  // Stack traces will only show in local development.  This is used to show them on local dev.
  allowStacktrace?: boolean;
  errorType?: string;
  children: ReactNode;
}

interface SharedProps {
  className?: string;
}

interface State {
  error: Error;
  errorInfo: ErrorInfo;
  eventId: string;
}

export const PageLevelError = ({ className }: SharedProps) => {
  const { t } = useTranslation();
  const refreshPage = useCallback(() => {
    location.reload();
  }, []);
  return (
    <div className={'js-error-boundary ' + (className ? className : ' text-center space-outer-top-xxl')} data-testid='error-boundary'>
      <img src='/assets/images/construction-barrier.svg' alt='Construction Barrier Icon' />
      <h1 className="space-outer-top-lg">{t('SOMETHING_WENT_WRONG')}</h1>
      <p className="space-outer-top-md space-outer-bottom-lg">{t('THE_SITE_COULD_BE_TEMPORARILY_UNAVAILABLE_OR_TOO_B')}</p>
      <Button className="space-outer-top-md" onClick={refreshPage} btnPrimary resource="REFRESH" />
    </div>
  );
};

export const SiteLevelError = ({ className }: SharedProps) =>
  <div className="js-error-boundary" data-testid='error-boundary'>
    <div className="carrier-logo space-outer-top-xl space-outer-left-lg" aria-hidden={true}>
      <img src="/assets/images/NavCarrier_Color.png" alt="NavisphereCarrier" />
    </div>
    <PageLevelError className={className ? className : null} />
  </div>;

export const PageNotFound = ({ className }: SharedProps) => {
  const auth = useRepository(AuthRepository);
  const user = useSelector(state => state.auth?.user);
  const activeMembershipPermissions = useSelector(state => state.userManagement?.activeMembership?.permissions);
  const { t } = useTranslation();


  return (
    <div className={'js-error-boundary ' + (className ? className : ' text-center space-outer-top-xxl')} data-testid='error-boundary'>
      {/*SVG of traffic cone*/}
      <svg width="150px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <path fill="#a9d5f1"
          d="M50 91.2V78.1c-15.4 0-28.3-5.3-28.4-11.9l2.1-6.2-19.3 7.8c-5.5 2.2-5.7 6.3-.5 9.1l36.7 19.7c2.9 1.4 6.2 2.2 9.4 2.1v-7.5z" />
        <path fill="#5fb2e6"
          d="M95.7 67.8L76.3 60l2.1 6.2c-.1 6.6-13.1 11.9-28.4 11.9v20.5c3.3.1 6.5-.7 9.4-2.1l36.7-19.7c5.3-2.7 5.1-6.8-.4-9z" />
        <path fill="#fff"
          d="M50 43.2c8.6 0 15.7-2.3 17.9-7.7L63 21c-1.6 3.1-7.2 5.2-13 5.2S38.6 24.1 37 21l-5 14.6c2.4 5.3 9.4 7.6 18 7.6M50 78.1c15.3 0 28.3-5.3 28.4-11.9L76.3 60l-2.5-7.3c-.6 5.5-11.4 9.8-23.8 9.8s-22.9-4.3-23.8-9.8L23.8 60l-2.1 6.2c.1 6.6 13 11.9 28.3 11.9" />
        <path fill="#a9d5f1"
          d="M57.6 4.9c-.8-2.4-4.4-3.7-7.6-3.7v25c5.8 0 11.4-2.1 13-5.2-2.1-6.8-4.2-12.5-5.4-16.1zM50 43.2v19.4c12.4 0 23.2-4.4 23.8-9.8L68 35.5c-2.2 5.3-9.3 7.7-18 7.7z" />
        <path fill="#d2e9f7"
          d="M42.5 4.9c-1.2 3.7-3.2 9.4-5.4 16.2 1.6 3.1 7.1 5.2 13 5.2v-25c-3.3-.1-6.9 1.2-7.6 3.6zM50 62.5V43.2c-8.7 0-15.7-2.3-17.9-7.6l-5.9 17.1c.9 5.5 11.4 9.8 23.8 9.8z" />
      </svg>
      <h1 className="space-outer-top-lg">{t("PAGE_NOT_FOUND")}</h1>
      <p className="space-outer-top-md space-outer-bottom-lg">{t("SORRY_THIS_PAGE_DOES_NOT_EXIST_TRY_GOING_BACK_TO_T")}</p>
      <AnchorButton btnPrimary href={auth.getBestPotentialUserStartPageRoute(user, activeMembershipPermissions).pathname} resource="RETURN_TO_SITE" />
    </div>
  );
};

export const DataLevelError = ({ className }: SharedProps) => {
  const { t } = useTranslation();

  return (
    <span className={'js-error-boundary ' + (className ? className : ' flex')} data-testid='error-boundary'>
      {t('ERROR')} &nbsp;
      <Popover
        content={t('WE_WERE_UNABLE_TO_RETRIEVE_THIS_INFORMATION_PLEASE')}
        triggerClassName="btn btn-clear error-popover-trigger"
        announceToScreenReader="OPEN_POP_OVER_DIALOG"
        trigger={
          <div>
            <svg className="error-icon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
            </svg>
          </div>
        }
      />
    </span>
  );
};

export const SectionLevelError = ({ className }: SharedProps) => {
  const { t } = useTranslation();

  return (
    <p className={'js-error-boundary ' + (className ? className : '  space-none')} data-testid='error-boundary'>
      <em>{t('WE_WERE_UNABLE_TO_RETRIEVE_THIS_INFORMATION_PLEASE')}</em>
    </p>
  );
};

export class ErrorBoundary extends Component<BoundaryProps & SharedProps, State> {
  state = { error: null, errorInfo: null, eventId: null };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {

    // unable to find chunk because new version was deployed.  Reload the page.
    if (error?.message?.includes('chunk')) {
      return window.location.reload(); // force cache-breaking
    }

    // Report React error to Datadog
    addReactError(error, errorInfo);

    this.setState({
      error,
      errorInfo
    });
  }

  renderError() {
    const { allowStacktrace, errorType, className } = this.props;
    const { error, errorInfo } = this.state;
    return (
      this.props.render
        ? this.props.render(error, errorInfo)
        : <>
          {(errorType === DATA_LEVEL_ERROR) && <DataLevelError className={className} />}
          {(errorType === PAGE_LEVEL_ERROR) && <PageLevelError className={className} />}
          {(errorType === SITE_LEVEL_ERROR) && <SiteLevelError className={className} />}
          {(errorType !== DATA_LEVEL_ERROR)
            && (errorType !== PAGE_LEVEL_ERROR)
            && (errorType !== SITE_LEVEL_ERROR)
            && <SectionLevelError className={className} />
          }
          {allowStacktrace && isLocalDev() &&
            <div title={error.stack}>
              <div>message: {error.message}</div>
              <div>stackTrace:
                <pre>{error.stack}</pre>
              </div>
              <div>Component StackTrace:
                <pre>{errorInfo.componentStack}</pre>
              </div>
            </div>
          }
        </>
    );
  }

  renderChildren() {
    return (
      <>
        {this.props.children ?? null}
      </>
    );
  }

  render() {
    return !this.state.error
      ? <>
        {this.renderChildren()}
      </>
      : this.renderError();
  }
}

/* BELOW: Code for testing error boundry*/

interface BugThrowerProps {
  errorClue?: string;
}

// button component that throws error
export class BuggyButton extends Component<BugThrowerProps> {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  state = { threwError: false };

  handleClick() {
    this.setState({
      threwError: true
    });
  }

  render() {
    if (this.state.threwError) {
      throw new Error('I crashed!');
    }
    return <button title={this.props.errorClue} onClick={this.handleClick}>Throw Error</button>;
  }
}

// test component that catches error using error boundary component
export class BuggyComponent extends Component {
  render() {
    return (
      <div>
        <ErrorBoundary errorType={PAGE_LEVEL_ERROR}>
          <h4>Page Level</h4>
          <p>These two counters are inside the <b>same</b> error boundary. If one crashes, the error boundary will
            replace both of them.</p>
          <BuggyButton />
          <br />
          <BuggyButton />
        </ErrorBoundary>
        <hr />
        <ErrorBoundary>
          <h4>Section Level</h4>
          <p>These two counters are inside the <b>same</b> error boundary. If one crashes, the error boundary will
            replace both of them.</p>
          <p>This is our fallback error. If the error type is not 'page level' or 'data-level', then it is 'section
            level'.</p>
          <BuggyButton />
          <br />
          <BuggyButton />
        </ErrorBoundary>
        <hr />
        <h4>Data Level</h4>
        <p>These two counters are each inside of <b>their own</b> error boundary. So if one crashes, the other is not
          affected.</p>
        <ErrorBoundary errorType={DATA_LEVEL_ERROR}><BuggyButton /></ErrorBoundary>
        <br className="space-outer-bottom-md" />
        <ErrorBoundary errorType={DATA_LEVEL_ERROR}><BuggyButton /></ErrorBoundary>
      </div>
    );
  }
}
