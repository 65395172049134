import { CarrierDetail } from 'shared/models/carrier/carrier-detail.model';

const fromCarrier: SelectorCreator<CarrierDetail> = innerSelector => (state, ownProps) => innerSelector(state?.auth?.carrier, ownProps);

export function carrier<TOwnProps>(state: NavCarrierState, ownProps: TOwnProps) {
  return fromCarrier(currentCarrier => ({ carrier: currentCarrier }))(state, ownProps);
}

export namespace carrier {
  export const isEuropean = fromCarrier(currentCarrier => ({ isEuropean: currentCarrier.isEuropean() }));
  export const isNorthAmerican = fromCarrier(currentCarrier => ({ isNorthAmerican: currentCarrier.isNorthAmerican() }));
  export const carrierCode = fromCarrier(currentCarrier => ({ carrierCode: currentCarrier.carrierCode }));
  export const continentCode = fromCarrier(currentCarrier => ({ continentCode: currentCarrier.getContinentCode() }));
}