import { buildUrl, post, patch } from '@lib/api';
import Result from '@lib/result';

const baseUrl = apiConfig.offersAPI;
const sourceSystemHeaderKey = 'Web-NavCarrier';

export async function postCreateOfferAsync(createOffer: CreateOfferRequestJSON): Promise<Result<CreateOfferResponseJSON>> {
  const customHeaders: any = {
    'X-SourceSystem': sourceSystemHeaderKey,
  };

  const result = await post<Result<CreateOfferResponseJSON>>(
    buildUrl(baseUrl, 'offers/offer'),
    customHeaders,
    createOffer);

  return result.success
    ? Result.Success(result.value)
    : Result.Fail(result.error);
}

export async function patchAcceptOfferAsync(acceptOffer: AcceptOfferRequestJSON): Promise<Result<null>> {
  const customHeaders: any = {
    'X-SourceSystem': sourceSystemHeaderKey,
  };

  const result = await patch<Result<null>>(
   buildUrl(baseUrl, 'offers/accept'),
   customHeaders,
   acceptOffer);

  return result.success
   ? Result.Success(result.value)
   : Result.Fail(result.error);
}

export async function postSearchOffersAsync(searchOffers: SearchOfferRequestJSON): Promise<Result<OfferModelJSON[]>> {
  const customHeaders: any = {
    'X-SourceSystem': sourceSystemHeaderKey,
  };

  const result = await post<Result<SearchOfferResponseJSON>>(
   buildUrl(baseUrl, 'offers/search'),
   customHeaders,
   searchOffers);

  return result.success
   ? Result.Success(result.value)
   : Result.Fail(result.error);
}

export default {
  postCreateOfferAsync,
  patchAcceptOfferAsync,
  postSearchOffersAsync
};
