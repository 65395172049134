import { useStoredUser, useStoredCarrierDetail } from 'app/hooks/store/use-stored-user.hook';
import { CarrierDetail } from 'shared/models/carrier/carrier-detail.model';
import { User } from 'shared/models/user.model';
import { LDClient } from 'launchdarkly-js-client-sdk';
import { LDFlagSet } from 'launchdarkly-js-sdk-common';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';
import { LocationInformation, useLocationInformation } from './use-location-information.hook';

export interface UrlData {
  pageName: string;
  pageUrl: string;
  priorPage: string;
  priorUrl: string;
}

export interface UserData {
  userId: number;
  email?: string;
  carrierCode: string;
  carrierProgram: string;
  carrierQualificationStatus: string;
}

export interface EventMetaData {
  sitename: string;
  urlData: UrlData;
  user?: UserData;
  identifiedFeatures?: Features;
  features?: Features;
  isAnonymous?: boolean;
}

type Features = { [key: string]: { name: string, value: string } };

interface BuildAnalyticsEventMetaDataParameters {
  pageInfo: LocationInformation;
  flags: LDFlagSet;
  user: User;
  carrier: CarrierDetail;
  isAnonymous: boolean;
  client: LDClient;
  addUserEmail: boolean;
  ensureUserIsIdentified: boolean;
}

function buildAnalyticsEventMetaData({
  pageInfo,
  flags,
  user,
  carrier,
  isAnonymous,
  client,
  addUserEmail,
  ensureUserIsIdentified
}: BuildAnalyticsEventMetaDataParameters) {
  const { pageName: pagePath, pageUrl } = pageInfo;
  const features: Features = {};
  for (let key in flags) {
    //Seeing issue with Adobe not handling false value. Using string as workaround.
    features[key] = { name: key, value: `${flags[key] || false}` };
  }
  // Generate readable page name from the first route path. We assume it is home when path return empty string.
  // Get the first path after the base url "/" and replace any special characters with space.
  // Ex: "/find-loads/single" will be converted to "find loads"
  const format = (value: string) => value?.split('/')[1].replace(/[^a-zA-Z ]/g, ' ') || 'home';
  const metaData: EventMetaData = {
    sitename: 'navisphere carrier',
    urlData: {
      pageName: format(pagePath.current),
      pageUrl: pageUrl.current,
      priorPage: format(pagePath.prior),
      priorUrl: pageUrl.prior,
    },
  };

  if (ensureUserIsIdentified && !isAnonymous) {
    metaData.features = features;
  }

  if (!ensureUserIsIdentified) {
    metaData.features = features;
  }

  metaData.isAnonymous = isAnonymous;

  if (user) {
    const { userId, email } = user;
    const carrierCode = carrier?.carrierCode;
    metaData.user = {
      userId,
      carrierCode,
      carrierProgram: carrier?.capCode ?? null,
      carrierQualificationStatus: carrier?.carrierQualificationStatus ?? null,
    };

    if (addUserEmail) {
      metaData.user.email = email;
    }
  }
  return metaData;
}

export const useAnalyticsEventMetaData = (
  addUserEmail: boolean = false,
  ensureUserIsIdentified: boolean = true
): EventMetaData => {
  const pageInfo = useLocationInformation();
  const user = useStoredUser();
  const carrier = useStoredCarrierDetail();
  const client = useLDClient();
  const flags = useFlags();
  let isAnonymous = isAnonymousLdUser(client);

  return useMemo(() => {
    return buildAnalyticsEventMetaData({
      pageInfo,
      flags,
      user,
      carrier,
      isAnonymous,
      client,
      addUserEmail,
      ensureUserIsIdentified
    });
  }, [
    pageInfo.pageName,
    pageInfo.pageUrl,
    user,
    ensureUserIsIdentified,
    flags,
    isAnonymous,
  ]);
};

function isAnonymousLdUser(client: LDClient) {
  return (!client || typeof client.getUser !== 'function') ? true : client.getUser()?.anonymous ?? false;
}
