import { BaseModel } from 'shared/models/base.model';
import { OfferStatus } from 'shared/enums/offer-status.enum';
import { OfferType } from 'shared/enums/offer-type.enum';
import AvailableLoadOfferRejectionReason from 'shared/enums/available-load-offer-rejection-reason';

export class Offer extends BaseModel<OfferJSON> {
  binPrice?: number;
  offerId?: number;
  bookOfferId?: string;
  offerRequestId?: string;
  loadNumber: number;
  displayStatus: OfferStatus;
  offerPrice: number;
  enteredDate: Date;
  carrierCode?: string;
  offerNote: string;
  currencyCode: string;
  chrRep?: string;
  originLocation: LocationJSON | null;
  destinationLocation: LocationJSON | null;
  offerType: OfferType;
  rejectionReason?: string;
  acceptedByDataScience?: boolean;
  isShipmentAvailable?: boolean;
  isShipmentOfferable?: boolean;
  isShipmentCounterOfferable?: boolean;
  binCostKey?: string;
  carrierGroupId?: string;
  expirationDateTimeUtc?: Date;
  private enteredDateAdjusted?: Date;

  constructor(json?: OfferJSON) {
    super(json);

    if (json) {
      if (json.enteredDateUtc) {
        if (json.enteredDateUtc.slice(-1) !== 'Z') {
          this.enteredDateAdjusted = this.processDate(`${json.enteredDateUtc}Z`);
        } else {
          this.enteredDateAdjusted = this.processDate(json.enteredDateUtc);
        }
      } else if (json.enteredDate) {
        this.enteredDateAdjustedTimeZone = this.processDate(json.enteredDate);
      }

      if (json.expirationDateTimeUtc) {
        if (json.expirationDateTimeUtc.slice(-1) !== 'Z') {
          this.expirationDateTimeUtc = this.processDate(`${json.expirationDateTimeUtc}Z`);
        } else {
          this.expirationDateTimeUtc = this.processDate(json.expirationDateTimeUtc);
        }
      }
    }
  }

  get enteredDateAdjustedTimeZone() {
    return this.enteredDateAdjusted;
  }

  set enteredDateAdjustedTimeZone(date: Date) {
    const dateInIso = date.toISOString();
    if (dateInIso && dateInIso.slice(-1) !== 'Z') {
      this.enteredDateAdjusted = this.processDate(`${dateInIso}Z`);
    } else {
      this.enteredDateAdjusted = this.processDate(dateInIso);
    }
  }

  get open() {
    return [OfferStatus.PENDING, OfferStatus.ACCEPTED].includes(this.displayStatus);
  }

  get offerStatusCalculated() {
    if (this.offerType === OfferType.Load && [OfferStatus.OPEN, OfferStatus.PENDING, OfferStatus.ACCEPTED, OfferStatus.COUNTERED].includes(this.displayStatus)) {
      return OfferStatus.COUNTERED;
    }
    if (this.displayStatus === OfferStatus.REJECTED && this.rejectionReason?.toUpperCase() === AvailableLoadOfferRejectionReason.IGNORED) {
      return OfferStatus.EXPIRED;
    }
    if ([OfferStatus.NOT_CONSIDERED, OfferStatus.UNABLE_TO_PROCESS].includes(this.displayStatus)) {
      return OfferStatus.PENDING;
    }
    return this.displayStatus;
  }

  get offerStatusSorting() {
    switch (this.offerStatusCalculated) {
      case OfferStatus.ACCEPTED:
        return 0;
      case OfferStatus.COUNTERED:
        return 10;
      case OfferStatus.EXPIRED:
        return 20;
      case OfferStatus.REVOKED:
      case OfferStatus.NO_LONGER_AVAILABLE:
        return 30;
      case OfferStatus.PENDING:
        return 40;
      case OfferStatus.REJECTED:
        return 50;
      default:
        return 100;
    }
  }

  toJson() {
    const jsonOffer = {
      ...super.toJson(),
      enteredDate: this.convertDateToDateString(this.enteredDate)
    };
    delete jsonOffer['enteredDateAdjusted'];
    return jsonOffer;
  }
}
