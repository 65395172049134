import { LoadSummaryOffer } from 'shared/models/loads/load-summaries/load-summary-carrier-offer';
import { OfferStatus as OfferStatusEnum } from 'shared/enums/offer-status.enum';
import { Offer } from 'shared/models/offers/offer.model';
import AvailableLoadOfferStatus from 'shared/enums/available-load-offer-status';
import { OfferType } from 'shared/enums/offer-type.enum';
import AvailableLoadOfferRejectionReason from '@shared/enums/available-load-offer-rejection-reason';

export function offerToLoadSummaryOfferMapper(offer: Offer): LoadSummaryOffer {
  if (!offer) {
    return null;
  }
  const offerStatus = offer?.displayStatus ? OfferStatusEnum[offer.displayStatus] : OfferStatusEnum[OfferStatusEnum.UNKNOWN];

  const loadSummaryOffer: LoadSummaryOffer = {
    createdDateTime: offer?.enteredDateAdjustedTimeZone?.toISOString(),
    acceptedByDataScience: offer.acceptedByDataScience,
    offerType: offer.offerType,
    currencyCode: offer.currencyCode,
    offerStatus: offerStatus,
    price: offer.offerPrice,
    rejectionReason: offer.rejectionReason,
    offerId: offer.offerId,
    bookOfferId: offer.bookOfferId,
    offerRequestId: offer.offerRequestId,
    isFinalNegotiation: !offer.isShipmentOfferable && !offer.isShipmentCounterOfferable,
    expirationDateTimeUtc: offer?.expirationDateTimeUtc?.toISOString()
  };
  return loadSummaryOffer;
}

export function notificationToLoadSummaryOfferMapper(notification: OfferResultNotification): LoadSummaryOffer {

  const loadSummaryOffer: LoadSummaryOffer = {
    offerRequestId: notification.transactionId,
    offerId: notification.offerId,
    bookOfferId: notification.offerIdGuid,
    price: notification.price,
    currencyCode: notification.currencyCode,
    offerStatus: negotiationResultToStatusMapper(notification.offerNegotiationResult),
    offerType: notification.offerNegotiationResult == 3 ? OfferType.Load : OfferType.Truck,
    acceptedByDataScience: notification.offerNegotiationResult == 0,
    rejectionReason: notification.rejectionReason?.toUpperCase() === 'CARRIERVALIDATION' ? AvailableLoadOfferRejectionReason.CARRIER_VALIDATION : notification.rejectionReason,
    isFinalNegotiation: notification.isFinalNegotiation,
    createdDateTime: notification.receivedDate?.toISOString(),
    expirationDateTimeUtc: notification.expirationDateTimeUtc,
  };

  return loadSummaryOffer;
}

export function negotiationResultToStatusMapper(result: number): string {
  switch (result) {
    case 0:
      return AvailableLoadOfferStatus.ACCEPTED;
    case 1:
      return AvailableLoadOfferStatus.REJECTED;
    case 2:
    case 3:
    default:
      return AvailableLoadOfferStatus.OPEN;
  }
}
