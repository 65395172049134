import { selectByKey } from '../selector-utils';

import { user as userSelectors } from './auth/user.selectors';
import { carrier as carrierSelectors } from './auth/carrier.selectors';
import { pending as pendingSelectors } from './auth/pending.selectors';

const fromAuth: SelectorCreator<AuthState> = innerSelector => (state, ownProps) => innerSelector(state.auth, ownProps);

export function auth<TOwnProps>(state: NavCarrierState, ownProps: TOwnProps) {
 return fromAuth(authState => ({ auth: authState }))(state, ownProps);
}

export namespace auth {
 export const user = userSelectors;
 export const pending = pendingSelectors;
 export const isACSUser = fromAuth(selectByKey('isACSUser'));
 export const carrier = carrierSelectors;
 export const insuranceStatusConfirmed = fromAuth(selectByKey('insuranceStatusConfirmed'));
 export const insuranceStatusRequiresConfirmation = fromAuth(selectByKey('insuranceStatusRequiresConfirmation'));
 export const isAuthenticated = fromAuth(selectByKey('isAuthenticated'));
 export const errors = fromAuth(selectByKey('errors'));
 export const select = fromAuth;
}
